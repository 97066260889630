import React from "react"
import useWindowDimensions from "@hooks/useWindowDimensions"
import FeedbacksSection from "@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"
import { StyledSectionTitle } from "../../common-styles"
import Slider from "@common/Slider"
import FeedbackItem from "./FeedbackItem"
import { FEEDBACK_ITEMS, FEEDBACK_SECTION_ITEMS } from "../../constants"
import { StyledFeedbackWrapper } from "./styled"

const Feedback = () => {
  const { isMobile } = useWindowDimensions()
  const sliderConfig = { speed: 500 };

  return (
    <StyledFeedbackWrapper>
      {!isMobile && <FeedbacksSection title="Our Clients say" feedbacks={FEEDBACK_SECTION_ITEMS} />}
      {isMobile && (
        <>
         <StyledSectionTitle>Our Clients say</StyledSectionTitle>
         <Slider {...sliderConfig}>
          {FEEDBACK_ITEMS.map(props => (
            <FeedbackItem {...props} />
          ))}
        </Slider>
        </>
      )}
    </StyledFeedbackWrapper>
  )
}

export default Feedback
