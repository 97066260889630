import styled from "styled-components";
import { MOBILE_MIN_WIDTH } from "@constants"

export const StyledCompetencyCard = styled.div`
  display: flex;
  height: 16rem;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 1px;
  border: 1px solid #77617D;

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    height: 19.6rem;
  }

  @media only screen and (max-width: 421px) {
    height: 21.6rem;
  }

  @media only screen and (max-width: 375px) {
    height: 27.6rem;
  }

  @media only screen and (max-width: 375px) {
    height: 29.6rem;
  }

  @media only screen and (max-width: 302px) {
    height: 31.6rem;
  }

  @media only screen and (max-width: 283px) {
    height: 100%;
  }
`;

export const StyledListTitle = styled.span`
  color: rgba(255, 255, 255, 0.80);
  font-family: Nunito Sans;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 128.571%;
  letter-spacing: 0.56px;
  margin: 0;
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0 16px;
`;

export const StyledListItem = styled.li`
  color: rgba(255, 255, 255, 0.60);
  font-family: Nunito Sans;
  font-size: 1rem;
  line-height: 237.5%;
  letter-spacing: 0.8px;
  margin-bottom: 10px;
`;

export const StyledCompetencyWrapper = styled.div`
  overflow: hidden;
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  transition: transform 0.5s;
`;

export const StyledSliderMenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;
