import React, { useState } from 'react';
import SliderButtonMenu from './SliderButtonMenu';
import './Slider.css'; 

const Slider = ({ children, speed }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? children.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === children.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  return (
    <div className="slider">
      <div className="slider-content" style={{ transform: `translateX(-${currentIndex * 100}%)`, transition: `transform ease-out ${speed}ms` }}>
        {React.Children.map(children, (child, index) => (
          <div className="slide" key={index} style={{ width: `100%` }}>
            {child}
          </div>
        ))}
      </div>
      <SliderButtonMenu currentArray={children} currentIndex={currentIndex} handleBackButton={goToPrevious} handleForwardButton={goToNext} />
    </div>
  );
};

export default Slider;