import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledFeedbackWrapper = styled.div``;

export const StyledFeedbackItem = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 270px;
    padding: 32px;
    background: #22072B;
    max-width: 420px;
    flex: 1;
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        max-width: 100%;
    }
`;

export const StyledFeedbackItemTitleContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: baseline;
    gap: 8px;
`;

export const StyledQuote = styled.p`
    margin: 0;
    &::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M14.2771 14.8339V24H5V17.9557C5 15.6531 5.39759 13.572 6.19277 11.7122C6.98795 9.80812 8.33534 7.90406 10.2349 6L13.6145 8.65682C12.5542 9.80812 11.7369 10.8708 11.1627 11.845C10.5884 12.8192 10.2129 13.8155 10.0361 14.8339H14.2771ZM27 14.8339V24H17.7229V17.9557C17.7229 15.6531 18.1205 13.572 18.9157 11.7122C19.7108 9.80812 21.0582 7.90406 22.9578 6L26.3374 8.65682C25.2771 9.80812 24.4598 10.8708 23.8855 11.845C23.3112 12.8192 22.9357 13.8155 22.759 14.8339H27Z" fill="%23C4B180"/></svg>');
    }
`;

export const StyledFeedbackItemFooter = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
`;

export const StyledFeedbackItemTitle = styled.h4`
    color: #C4B180;
    font-family: "Nunito Sans";
    font-size: 24px;
    margin: 0;
    font-weight: 200;
    line-height: 129.167%;
    letter-spacing: 0.48px;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      font-size: 1.4rem;
      line-height: 150%;
      letter-spacing: 0.4px;
}
`;

export const StyledClientContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledFeedbackItemClientName = styled.span`
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.32px;
`;

export const StyledFeedbackItemClientRole = styled.span`
    color: rgba(255, 255, 255, 0.80);
    font-family: "Nunito Sans";
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 153.846%;
    letter-spacing: 0.26px;
`;

export const StyledProductImageContainer = styled.span``;

export const StyledProductImage = styled.img`
    margin-top: 24px;
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
`;
