import React, { useState } from "react"
import useWindowDimensions from "@hooks/useWindowDimensions";
import { StyledSectionTitle, StyledSectionDescription } from "../../common-styles";
import { SliderButtonMenu } from "..";
import CompetencyCard from "./CompetencyCard";
import { 
  COMPETENCIES, 
  COMPETENCIES_DESKTOP, 
  SLIDER_MENU_DIRECTIONS 
} from "../../constants";
import { 
  StyledCompetencyWrapper, 
  StyledCardsContainer, 
  StyledSliderMenuContainer 
} from "./styled";

const Competencies = () => {
  const { isDesktop } = useWindowDimensions();
  const [currentSingleIndex, setCurrentSingleIndex] = useState(0);
  const [currentPairIndex, setCurrentPairIndex] = useState(0);

  const currentArray = isDesktop ? COMPETENCIES_DESKTOP : COMPETENCIES;
  const currentIndex = isDesktop ? currentPairIndex : currentSingleIndex;

  const handleButton = (direction) => {
    const offset = direction === SLIDER_MENU_DIRECTIONS.BACK ? -1 : 1;
    const updateIndex = (prev) => (prev + offset + currentArray.length) % currentArray.length;
    isDesktop ? setCurrentPairIndex(updateIndex) : setCurrentSingleIndex(updateIndex);
  }

  const buildTranslateObject = (coefficient, width) => {
    return ({ transform: `translateX(-${currentIndex * coefficient}%)`, width: `${width}%`});
  };

  const transformAnimation = isDesktop ? buildTranslateObject(50, 200) : buildTranslateObject(25, 400);
  
  return (
    <StyledCompetencyWrapper>
      <StyledSectionTitle>Our AI/ML competencies</StyledSectionTitle>
      <StyledSectionDescription>
        Impressit is an AI/ML software development company that helps businesses
        worldwide automate their operations, improve their performance, and
        achieve an outstanding user experience by building custom AI- and
        ML-powered software solutions.
      </StyledSectionDescription>
      <StyledCardsContainer style={transformAnimation}> 
       {COMPETENCIES.map((props) => <CompetencyCard {...props} />)}
      </StyledCardsContainer>
      <StyledSliderMenuContainer>
      <SliderButtonMenu 
       currentArray={currentArray} 
       currentIndex={currentIndex}
       handleBackButton={() => handleButton(SLIDER_MENU_DIRECTIONS.BACK)}
       handleForwardButton={() => handleButton(SLIDER_MENU_DIRECTIONS.FORWARD)}
      />
      </StyledSliderMenuContainer>
    </StyledCompetencyWrapper>
  )
}

export default Competencies
