import React from "react";
import { 
    StyledCompetencyCard,
    StyledList,
    StyledListTitle,
    StyledListItem
} from "../styled";

const CompetencyCard = ({ title, items }) => {
    return (
        <StyledCompetencyCard>
        <StyledListTitle>{title}</StyledListTitle>
        <StyledList>
            {items.map((item) => <StyledListItem>{item}</StyledListItem>)}
        </StyledList>
      </StyledCompetencyCard>
    )

};

export default CompetencyCard;