import styled from "styled-components";

export const StyledSliderMenuContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 24px;
    gap: 40px;
`;

export const StyledSliderMenuButton = styled.button`
    padding: 6px;
    background: inherit;
    border: 1px solid rgba(255, 255, 255, 0.30);
    cursor: pointer;

    &:hover {
        border-color: var(--main-gold);
    }
`;

export const StyledSliderMenuIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const StyledSliderMenuIndicator = styled.div`
    height: 8px;
    width: 8px;
    background: ${({ background }) => background};
`;