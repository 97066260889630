import React from "react"
import { ReactSVG } from "react-svg";
import leftArrow from "@images/ai/leftArrow.svg"
import rightArrow from "@images/ai/rightArrow.svg"
import { size } from "../../../../helpers";
import { 
  NEXT_BUTTON_ARIA_LABEL, 
  PREVIOUS_BUTTON_ARIA_LABEL 
} from "../constants";
import { 
    StyledSliderMenuContainer,
    StyledSliderMenuButton,
    StyledSliderMenuIndicatorContainer,
    StyledSliderMenuIndicator
} from "./styled";

const SliderButtonMenu = ({ currentArray, currentIndex, handleBackButton, handleForwardButton }) => {
  return (
    <StyledSliderMenuContainer>
    <StyledSliderMenuButton 
      aria-label={PREVIOUS_BUTTON_ARIA_LABEL} 
      onClick={handleBackButton}
      >
      <ReactSVG src={leftArrow} />
    </StyledSliderMenuButton>
    <StyledSliderMenuIndicatorContainer>
      {Array.from({ length: size(currentArray) })?.map((_, i) => (
        <StyledSliderMenuIndicator
          background={currentIndex === i ? "#FFF" : "rgba(255, 255, 255, 0.10)"}
        />
      ))}
    </StyledSliderMenuIndicatorContainer>
    <StyledSliderMenuButton 
      aria-label={NEXT_BUTTON_ARIA_LABEL} 
      onClick={handleForwardButton}
      >
      <ReactSVG src={rightArrow} />
    </StyledSliderMenuButton>
  </StyledSliderMenuContainer>
  )
}

export default SliderButtonMenu
